/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
import React, { useCallback, useEffect, useState, useMemo } from 'react';

import Radio from '../Radio';
import CardBrand from './cardBrand';

import * as func from './functions';
import * as encryption from './encryption';
import * as detective from '../detective';
import * as network from './network';
import * as util from '../util';

import { useDebounce, useWindowListener } from './hooks';
import { brandMap } from './functions';

const getTiming = () => Math.round(Date.now());

//Action Constants
const HOST_TOKEN = 'host:hostToken';
const TRANSFER_PART1 = 'host:transfer_part1';
const TRANSFER_PART2 = 'host:transfer_part2';
const TOKENIZE = 'host:tokenize';
const CANCEL_TRANSFER = 'host:cancel_transfer';
const BARCODE = 'host:barcode';
const CALCULATE_FEE = 'host:calculate_fee';

const ENCODED_ACTIONS = [HOST_TOKEN, CALCULATE_FEE];

const AUTOFILL = 'field:autofill';
const KEYS = 'field:keys';
const BLUR = 'field:blur';
const CLICKS = 'field:clicks';
const FOCUS = 'field:focus';
const ERROR = 'field:error';
const INSPECTION = 'field:inspected';
const ATTESTATION = 'field:attestation';

// Constants for incoming message types
const HOST_TOKEN_TYPE = 'host_token';
const TRANSFER_CONFIRMATION_TYPE = 'transfer_confirmation';
const CANCEL_TYPE = 'cancel';
const BARCODE_COMPLETE_TYPE = 'barcode_complete';
const TRANSFER_COMPLETE_TYPE = 'transfer_complete';
const TOKENIZE_COMPLETE_TYPE = 'tokenize_complete';
const CALCULATE_FEE_TYPE = 'calculate_fee_complete';
const ERROR_TYPE = 'error';

const ENCRYPTED_MESSAGES = [
  TRANSFER_CONFIRMATION_TYPE,
  BARCODE_COMPLETE_TYPE,
  TRANSFER_COMPLETE_TYPE,
  TOKENIZE_COMPLETE_TYPE,
];

const parseToken = token => {
  if (token) {
    const json = decodeURI(token);
    const decodedJson = window.atob(json);
    const object = JSON.parse(decodedJson);
    // Set the default style object and ensure at least default is defined with a blank object
    const defaultStyleObject = { default: {}, success: {}, error: {} };
    const styleObject = object.styles ? object.styles : defaultStyleObject;
    if (!styleObject.default) styleObject.default = {};
    return {
      origin: object.origin,
      placeholders: object.placeholders || {},
      style: styleObject,
      hidePlaceholder: !!styleObject.hidePlaceholder,
      connectedSession: object.session,
      amount: object.amount,
      country: object.country,
    };
  }
  return {
    origin: false,
    placeholders: {},
    style: { default: {}, success: {}, error: {} },
    hidePlaceholder: false,
    connectedSession: false,
    country: 'USA',
  };
};

export default function HostedField({ field }) {
  const query = util.useQuery();
  const token = query.get('token');

  const [changeCount, setChangeCount] = useState({ count: 0 });
  const [keyPrints, setKeyprints] = useState([]);
  const [clickPrints, setClickPrints] = useState([]);

  const [fieldsReceived, setFieldsReceived] = useState({});
  const [boxed, setBoxed] = useState(false);

  const [idempotency, setIdempotency] = useState(false);
  const [hostToken, setHostToken] = useState();
  const [tokenTimeout, setTokenTimeout] = useState(false);
  const [sessionKey, setSessionKey] = useState();

  const [value, setValue] = useState('');
  const [stateObject] = useState({});
  const [cardBrand, setCardBrand] = useState('');
  const [calculatedFee, setCalculatedFee] = useState({});

  const [focusSent, setFocusSent] = useState(false);
  const [focused, setFocused] = useState(false);
  const [inFocus, setInFocus] = useState(false);
  const [blurSent, setBlurSent] = useState(false);
  const [blurred, setBlurred] = useState(false);
  const [inspected, setInspected] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [ptSocket, setPtSocket] = useState();
  const [messageChannel] = useState({});
  const [ptToken, setPtToken] = useState(false);
  const [keyPair] = useState(encryption.generateKeyPair());

  const [transactingTypes] = useState([
    'card-number',
    'account-number',
    'cash-name',
  ]);
  const [valid, setValid] = useState([]);

  const {
    origin,
    placeholders,
    style,
    hidePlaceholder,
    connectedSession,
    amount: initialAmount,
    country,
  } = useMemo(() => parseToken(token), [token]);

  const [amount, setAmount] = useState(initialAmount);

  const placeholder = placeholders[field.type] || field.placeholder;

  // Hidden field IDs
  let CARD_NAME_ID = 'hidden-name';
  let CARD_CVV_ID = 'hidden-cvv';
  let CARD_EXP_ID = 'hidden-exp';
  let BILLING_LINE1_ID = 'hidden-line1';
  let BILLING_LINE2_ID = 'hidden-line2';
  let BILLING_CITY_ID = 'hidden-city';
  let BILLING_STATE_ID = 'hidden-state';
  let BILLING_ZIP_ID = 'hidden-zip';

  //Creates the hidden fields for autocomplete of card and billing address
  const setHiddenFields = () => {
    let result;

    const name = (
      <input
        autoComplete="cc-name"
        className="hidden"
        id={CARD_NAME_ID}
        key="cc-name"
        required=""
        tabIndex="-1"
      />
    );
    const cvc = (
      <input
        autoComplete="cc-csc"
        className="hidden"
        id={CARD_CVV_ID}
        key="cc-cvv"
        required=""
        tabIndex="-1"
      />
    );
    const exp = (
      <input
        autoComplete="cc-exp"
        className="hidden"
        id={CARD_EXP_ID}
        key="cc-exp"
        required=""
        tabIndex="-1"
      />
    );
    const billLine1 = (
      <input
        autoComplete="address-line1"
        className="hidden"
        id={BILLING_LINE1_ID}
        key="cc-line1"
        required=""
        tabIndex="-1"
      />
    );
    const billLine2 = (
      <input
        autoComplete="address-line2"
        className="hidden"
        id={BILLING_LINE2_ID}
        key="cc-line2"
        required=""
        tabIndex="-1"
      />
    );
    const billCity = (
      <input
        autoComplete="address-level2"
        className="hidden"
        id={BILLING_CITY_ID}
        key="cc-city"
        required=""
        tabIndex="-1"
      />
    );
    const billState = (
      <input
        autoComplete="address-level1"
        className="hidden"
        id={BILLING_STATE_ID}
        key="cc-state"
        required=""
        tabIndex="-1"
      />
    );
    const billZip = (
      <input
        autoComplete="postal-code"
        className="hidden"
        id={BILLING_ZIP_ID}
        key="cc-zip"
        required=""
        tabIndex="-1"
      />
    );

    switch (field.type) {
      case 'card-number':
        result = [
          name,
          cvc,
          exp,
          billLine1,
          billLine2,
          billCity,
          billState,
          billZip,
        ];
        break;
      case 'billing-line1':
        result = [billLine2, billCity, billState, billZip];
        break;
      default:
        //Do nothing because it is only needed for the two fields
        break;
    }
    return result;
  };

  const MOUSE_UP = 'up';
  const MOUSE_DOWN = 'down';
  const MOUSE_CONTEXT = 'context';

  const onMouse = useCallback(
    eventType => e => {
      const clickPrint = {
        mouse: eventType,
        button: e.button,
        timing: getTiming(),
      };
      setClickPrints(clickPrints.concat([clickPrint]));
    },
    [clickPrints],
  );

  const onKeyUp = useCallback(
    e => {
      const keyPrint = {
        metaKey: e.metaKey,
        repeat: e.repeat,
        charType: findCharType(e.key),
        timing: getTiming(),
      };
      setChangeCount({
        count: changeCount.count + 1,
        keyPrint,
      });
      setKeyprints(keyPrints.concat([keyPrint]));
    },
    [keyPrints, changeCount.count],
  );

  const findCharType = key => {
    let numeric = /[0-9]/;
    return numeric.test(key) ? 'numeric' : key;
  };

  //This function is used to tell transacting fields that they have received all info from the siblings
  const verifyFieldsReceived = obj => {
    return Object.keys(obj).reduce((acc, val) => {
      return acc ? obj[val] !== false : acc;
    }, true);
  };

  //Sets the fields received object back to false so that the siblings can send new info
  const resetFieldsReceived = obj => {
    Object.keys(obj).forEach(v => {
      obj[v] = false;
    });
  };

  //Message types that are expected incoming from parent
  const attestationTypeMessage = message =>
    typeof message.type === 'string' &&
    message.type === 'pt-static:attestation';
  const transactTypeMessage = message =>
    typeof message.type === 'string' && message.type === 'pt-static:transact';
  const relayTypeMessage = message =>
    typeof message.type === 'string' && message.type === 'pt-static:relay';
  const cancelTypeMessage = message =>
    typeof message.type === 'string' && message.type === 'pt-static:cancel';
  const confirmTypeMessage = message =>
    typeof message.type === 'string' && message.type === 'pt-static:confirm';
  const updateAmountTypeMessage = message =>
    typeof message.type === 'string' &&
    message.type === 'pt-static:update-amount';
  const RESET_HOST = 'pt-static:reset_host';
  const CONNECTION_TOKEN = 'pt-static:connection_token';
  const requestHostTokenTypeMessage = message =>
    typeof message.type === 'string' &&
    (message.type === RESET_HOST || message.type === CONNECTION_TOKEN);
  const paymentDetailTypeMessage = message =>
    typeof message.type === 'string' &&
    message.type === 'pt-static:payment-detail';
  const tokenizeDetailTypeMessage = message =>
    typeof message.type === 'string' &&
    message.type === 'pt-static:tokenize-detail';

  // Parses the state and returns the object needed by tags to create a payment method
  const createPaymentMethodData = useCallback(
    (stateObject, fieldType) => {
      if (fieldType === 'card-number') {
        let [month, year] = stateObject['card-exp']?.split('/');
        return {
          name: stateObject['card-name'] || null,
          number: value.replace(/\D/g, ''),
          security_code: stateObject['card-cvv'],
          type: 'card',
          expiration_year: year.length === 2 ? `20${year}` : year,
          expiration_month: month,
          address: {
            city: stateObject['billing-city'],
            region: stateObject['billing-state'],
            postal_code: stateObject['billing-zip'],
            line1: stateObject['billing-line1'],
            line2: stateObject['billing-line2'],
            country: country,
          },
        };
      } else if (fieldType === 'account-number') {
        // Pull out the routing number for the bank code
        let routingNumber = stateObject['routing-number'];
        if (country === 'CAN') {
          // If the country is Canada, we need to format the routing number with the institution and transit numbers
          const institutionNumber = stateObject['institution-number'];
          const transitNumber = stateObject['transit-number'];
          routingNumber = `0${institutionNumber}${transitNumber}`;
        }
        return {
          account_number: value,
          account_type: stateObject['account-type'],
          bank_code: routingNumber,
          name: stateObject['account-name'],
          issuing_country_code: country,
          type: 'ach',
        };
      }
    },
    [value],
  );

  // Parses the state and returns a payor object based on the settings passed in
  const getPayorInfo = useCallback((payorInfo, state) => {
    if (payorInfo.same_as_billing === true) {
      let name = state['card-name'] || '';
      const nameArray = name.split(' ');
      const firstName = nameArray.shift() || '';
      const lastName = nameArray.join(' ');

      const fieldInfo = {
        first_name: firstName,
        last_name: lastName,
        personal_address: {
          city: state['billing-city'],
          country: 'USA',
          region: state['billing-state'],
          line1: state['billing-line1'],
          line2: state['billing-line2'],
          postal_code: state['billing-zip'],
        },
      };
      return { ...payorInfo, ...fieldInfo };
    }
    return payorInfo;
  }, []);

  // Check if we have all the required fields to transact and returns true or false
  const isReadyToTransact = useCallback(
    stateObject => {
      if (field.type === 'card-number') {
        let valid =
          func.validCvv(stateObject['card-cvv']) &&
          func.validExp(stateObject['card-exp']) &&
          func.validCreditCard(value);
        // Validate postal code separate because it can be passed in
        let postalCodeValid = func.validPostalCode(stateObject['billing-zip']);
        if (postalCodeValid === false) {
          return [false, 'INVALID_PARAM: Invalid postal code'];
        }
        let result = valid && postalCodeValid;
        return [result, 'INVALID_PARAM: Invalid card details'];
      } else if (field.type === 'account-number') {
        let result =
          func.notEmpty(stateObject['account-name']) &&
          func.validAccountType(stateObject['account-type']) &&
          func.validAccountNumber(value);
        if (country === 'CAN') {
          // Validate institution and transit numbers if Canada
          result =
            result &&
            func.validInstitutionNumber(stateObject['institution-number']) &&
            func.validTransitNumber(stateObject['transit-number']);
        } else {
          // Validate routing number if not Canada
          result =
            result && func.validRoutingNumber(stateObject['routing-number']);
        }
        return [result, 'INVALID_PARAM: Invalid ach details'];
      } else if (field.type === 'cash-name') {
        let result =
          func.validCashContact(stateObject['cash-contact']) && value;
        return [result, 'INVALID_PARAM: Invalid cash details'];
      } else {
        return [false, 'INVALID_PARAM: Invalid field type'];
      }
    },
    [field.type, origin, value],
  );

  //This function is used to handle messages from the sibling frames to update the fields ready
  const handleStateRelay = useCallback(
    message => {
      stateObject[message.element] = message.value;
      fieldsReceived[message.element] = true;
      if (verifyFieldsReceived(fieldsReceived)) {
        let [ready, error] = isReadyToTransact(stateObject);
        if (ready) {
          util.sendMessage(
            {
              type: `pt-static:fields-ready`,
              element: field.type,
            },
            origin,
            messageChannel.channel,
          );
        } else {
          util.sendMessage(
            {
              type: `pt-static:error`,
              error,
              element: field.type,
            },
            origin,
            messageChannel.channel,
          );
          resetFieldsReceived(fieldsReceived);
        }
      }
    },
    [
      fieldsReceived,
      stateObject,
      isReadyToTransact,
      origin,
      messageChannel,
      field.type,
    ],
  );

  const propagateAutofill = useCallback(
    message => {
      setValue(message.value[field.type]);
    },
    [setValue, field.type],
  );

  //Checks to make sure we have everything needed to send to the socket and then sends it or adds it to a message backlog to be sent after reconnection
  const socketAction = useCallback(
    (action, sessionKey, encoded) => {
      encoded.sessionKey = sessionKey;
      const payload = JSON.stringify(encoded);
      const sized = new Blob([payload]).size;
      if (transactingTypes.includes(field.type)) {
        if (sized >= 32000) {
          network.sendSOS(
            `SOCKET_ACTION_FAILED: ${sized} > 32k, ACTION: ${action}, SESSION: ${sessionKey}`,
          );
        }
        if (
          ptSocket &&
          ptSocket.readyState === ptSocket.OPEN &&
          ENCODED_ACTIONS.includes(action)
        ) {
          //We can send the websocket action as long as the socket is open
          let message = {
            action,
            encoded: window.btoa(JSON.stringify(encoded)),
          };
          ptSocket.send(JSON.stringify(message));
        } else if (ptSocket && ptSocket.readyState === ptSocket.OPEN && boxed) {
          //We can send encrypted messages the websocket action as long as the socket is open, and we have the boxed and keypair values
          let message = {
            action,
            sessionKey: window.btoa(sessionKey),
            encoded: encryption.encrypt(boxed, encoded),
            publicKey: encryption.encodeKey(keyPair.publicKey),
          };
          ptSocket.send(JSON.stringify(message));
        } else if (action.includes('host')) {
          if (action === CALCULATE_FEE) {
            // Set the amount to null to not send another fee calc until the socket is reconnected
            setAmount(null);
            util.sendMessage(
              {
                type: `pt-static:fee_calc_reconnect`,
                field: field.type,
              },
              origin,
            );
          } else {
            // Only throw an error if the action we are trying to send is a host action
            util.sendMessage(
              {
                type: `pt-static:error`,
                error: `SOCKET_ERROR: Unable to send message to socket. Socket is not open.`,
                field: field.type,
              },
              origin,
            );
          }
        }
      } else {
        util.sendMessage(
          {
            type: `pt-static:relay`,
            value: {
              action,
              encoded,
            },
            siblingSocketEvent: true,
            element: field.type,
          },
          origin,
        );
      }
    },
    [boxed, transactingTypes, field.type, origin, keyPair, ptSocket],
  );

  const requestHostToken = useCallback(
    (token, origin) =>
      socketAction(HOST_TOKEN, sessionKey, {
        ptToken: token,
        origin,
        timing: getTiming(),
        session: connectedSession,
      }),
    [HOST_TOKEN, socketAction, sessionKey, connectedSession],
  );

  const requestTransferPart1 = useCallback(
    (
      hostToken,
      payment_method_data,
      payment_data,
      confirmation_needed,
      payor_info,
      pay_theory_data,
      metadata,
      sessionKey,
    ) => {
      let encoded = {
        hostToken,
        payment_method_data,
        payment_data,
        confirmation_needed,
        payor_info,
        pay_theory_data,
        metadata,
        timing: getTiming(),
      };
      socketAction(TRANSFER_PART1, sessionKey, encoded);
    },
    [socketAction],
  );

  const requestTransferPart2 = useCallback(
    (payment_prep, sessionKey) =>
      socketAction(TRANSFER_PART2, sessionKey, {
        payment_prep,
        timing: getTiming(),
      }),
    [TRANSFER_PART2, socketAction],
  );

  const requestCancel = useCallback(
    (payment_intent_id, sessionKey) =>
      socketAction(CANCEL_TRANSFER, sessionKey, {
        payment_intent_id,
        timing: getTiming(),
      }),
    [CANCEL_TRANSFER, socketAction],
  );

  const requestBarcode = useCallback(
    (hostToken, payment, metadata, payor_info, pay_theory_data, sessionKey) => {
      let encoded = {
        payment,
        timing: getTiming(),
        hostToken,
        metadata,
        payor_info,
        pay_theory_data,
      };
      socketAction(BARCODE, sessionKey, encoded);
    },
    [BARCODE, socketAction],
  );

  const requestTokenize = useCallback(
    (
      hostToken,
      payment_method_data,
      payor_info,
      payor_id,
      metadata,
      sessionKey,
    ) => {
      let encoded = {
        hostToken,
        payment_method_data,
        payor_info,
        pay_theory_data: {
          payor_id,
        },
        metadata,
        timing: getTiming(),
      };
      socketAction(TOKENIZE, sessionKey, encoded);
    },
    [TOKENIZE, socketAction],
  );

  const sendCalculateFee = useCallback(
    (sessionKey, amount, is_ach, bank_id) => {
      let encoded = {
        amount,
        is_ach,
        bank_id,
        timing: getTiming(),
      };
      socketAction(CALCULATE_FEE, sessionKey, encoded);
    },
    [socketAction],
  );

  const sendAutofill = useCallback(
    (sessionKey, field, autofillPrint) =>
      socketAction(AUTOFILL, sessionKey, {
        field,
        autofillPrint,
        sessionKey,
        timing: getTiming(),
      }),
    [AUTOFILL, socketAction],
  );

  const sendBlur = useCallback(
    (sessionKey, field, blurPrint) =>
      socketAction(BLUR, sessionKey, {
        field,
        blurPrint,
        sessionKey,
        timing: getTiming(),
      }),
    [BLUR, socketAction],
  );

  const sendClickPrints = useCallback(
    (sessionKey, field, clickPrints) =>
      socketAction(CLICKS, sessionKey, {
        field,
        clickPrints,
        sessionKey,
        timing: getTiming(),
      }),
    [CLICKS, socketAction],
  );

  const sendFocus = useCallback(
    (sessionKey, field, focusPrint) =>
      socketAction(FOCUS, sessionKey, {
        field,
        focusPrint,
        sessionKey,
        timing: getTiming(),
      }),
    [FOCUS, socketAction],
  );

  const sendKeyPrints = useCallback(
    (sessionKey, field, keyPrints) =>
      socketAction(KEYS, sessionKey, {
        field,
        keyPrints,
        sessionKey,
        timing: getTiming(),
      }),
    [KEYS, socketAction],
  );

  const sendInspection = useCallback(
    (sessionKey, field, inspection) =>
      socketAction(INSPECTION, sessionKey, {
        field,
        inspection,
        sessionKey,
        timing: getTiming(),
      }),
    [INSPECTION, socketAction],
  );

  const sendAttestation = useCallback(
    (sessionKey, field, attestation) =>
      socketAction(ATTESTATION, sessionKey, {
        field,
        attestation,
        sessionKey,
        timing: getTiming(),
      }),
    [ATTESTATION, socketAction],
  );

  const sendError = useCallback(
    (sessionKey, field, error) =>
      socketAction(ERROR, sessionKey, {
        field,
        error,
        sessionKey,
        timing: getTiming(),
      }),
    [ERROR, socketAction],
  );

  const KEY_PRINT_TYPE = 'key';
  const CLICK_PRINT_TYPE = 'click';

  // Parses the state and returns calls the tokenize function
  const handleTokenize = useCallback(
    tokenizeDetails => {
      let { metadata, payorInfo, payorId } = tokenizeDetails;
      const formattedPayorInfo = getPayorInfo(payorInfo, stateObject);
      const payment_method_data = createPaymentMethodData(
        stateObject,
        field.type,
      );
      requestTokenize(
        hostToken,
        payment_method_data,
        formattedPayorInfo,
        payorId,
        metadata,
        sessionKey,
      );
    },
    [
      hostToken,
      sessionKey,
      createPaymentMethodData,
      field,
      stateObject,
      requestTokenize,
    ],
  );

  // Parses the state and returns calls the transact function
  const handleTransact = useCallback(
    paymentDetails => {
      const payment_data = {
        fee_mode: paymentDetails.fee_mode,
        currency: 'USD',
        amount: paymentDetails.amount,
      };
      let { metadata, confirmation, payorInfo, payTheoryData } = paymentDetails;
      payorInfo = getPayorInfo(payorInfo, stateObject);
      const payment_method_data = createPaymentMethodData(
        stateObject,
        field.type,
      );
      requestTransferPart1(
        hostToken,
        payment_method_data,
        payment_data,
        confirmation,
        payorInfo,
        payTheoryData,
        metadata,
        sessionKey,
      );
    },
    [
      hostToken,
      ptSocket,
      sessionKey,
      createPaymentMethodData,
      getPayorInfo,
      field.type,
      requestTransferPart1,
    ],
  );

  const handleCashBarcode = useCallback(
    paymentDetails => {
      let payment = {
        buyer: value,
        amount: paymentDetails.amount,
        buyer_contact: stateObject['cash-contact'],
      };
      let { metadata, payorInfo, payTheoryData } = paymentDetails;
      requestBarcode(
        hostToken,
        payment,
        metadata,
        payorInfo,
        payTheoryData,
        sessionKey,
      );
    },
    [hostToken, sessionKey, stateObject, value, requestBarcode],
  );

  const releasePrints = useCallback(
    (sessionKey, field, prints, printType) => {
      if (Array.isArray(prints)) {
        if (printType === KEY_PRINT_TYPE) {
          sendKeyPrints(sessionKey, field, prints);
          setKeyprints([]);
        }
        if (printType === CLICK_PRINT_TYPE) {
          sendClickPrints(sessionKey, field, prints);
          setClickPrints([]);
        }
      }
    },
    [sendKeyPrints, sendClickPrints],
  );

  // useEffect(() => {
  //     if (sessionKey && transactingTypes.includes(field.type) && changeCount.count > 0 && inspected === false && boxed) {
  //         setInspected(true)
  //         try {
  //             (async () => {
  //                 try {
  //                     const inspected = await detective.inspectBrowser()
  //                     sendInspection(sessionKey, field.type, inspected)
  //                 } catch (e) {
  //                     sendError(sessionKey, field.type, e)
  //                 }
  //             })()
  //         } catch (error) {
  //             sendError(sessionKey, field.type, error)
  //         }
  //     }
  // }, [sessionKey, field.type, sendInspection, sendError, transactingTypes, inspected, changeCount, boxed])

  useEffect(() => {
    if (origin) {
      if (transactingTypes.includes(field.type)) {
        // Send message to SDK to request that the token be sent
        util.sendMessage(
          {
            type: `pt-static:pt_token_ready`,
            element: field.type,
          },
          origin,
        );
      }
    }
  }, [field.type, origin, transactingTypes]);

  useEffect(() => {
    // if (!sessionKey || !boxed) return
    if (blurred && blurSent === false) {
      setBlurSent(blurred);
      sendBlur(sessionKey, field.type, blurred);
      releasePrints(sessionKey, field.type, keyPrints, KEY_PRINT_TYPE);
      releasePrints(sessionKey, field.type, clickPrints, CLICK_PRINT_TYPE);
      setFocused(false);
    }
  }, [
    blurred,
    sendBlur,
    blurSent,
    field.type,
    sessionKey,
    keyPrints,
    clickPrints,
    releasePrints,
    boxed,
  ]);

  useEffect(() => {
    // if (!sessionKey || !boxed) return
    if (focused && focusSent === false) {
      setFocusSent(focused);
      sendFocus(sessionKey, field.type, focused);
      setBlurred(false);
    }
  }, [focused, sendFocus, focusSent, field.type, sessionKey, boxed]);

  const errorCallback = useCallback(
    event => {
      console.log(
        `Error in field: ${field.type}, refresh required: ${event.error.message}`,
      );
    },
    [field.type],
  );

  const messageCallback = useCallback(
    message => {
      const data = JSON.parse(message.data);
      let body = data?.body;
      if (ENCRYPTED_MESSAGES.includes(data?.type)) {
        const messagePublicKey = encryption.decodeKey(data.public_key);
        const messageBox = encryption.pairedBox(
          messagePublicKey,
          keyPair.secretKey,
        );
        body = encryption.decrypt(messageBox, body);
      }

      switch (data?.type) {
        case ERROR_TYPE:
          if (messageChannel.channel) {
            util.sendMessage(
              {
                type: `pt-static:error`,
                error: `SOCKET_ERROR: ${body}`,
                field: field.type,
              },
              origin,
              messageChannel.channel,
            );
          } else {
            util.sendMessage(
              {
                type: `pt-static:error`,
                error: `SOCKET_ERROR: ${body}`,
                field: field.type,
              },
              origin,
            );
          }
          break;
        case HOST_TOKEN_TYPE:
          setHostToken(body.hostToken);
          setSessionKey(body.sessionKey);
          let socketKey = encryption.decodeKey(body.publicKey);
          setBoxed(encryption.pairedBox(socketKey, keyPair.secretKey));
          if (tokenTimeout) clearTimeout(tokenTimeout);
          const hostTokenTimeout = setTimeout(() => {
            // setTimeout to close the socket if still open after token expires 14 minutes later
            if (ptSocket?.readyState === ptSocket?.OPEN && ptSocket) {
              ptSocket.close();
            }
          }, 14 * 60000);
          setTokenTimeout(hostTokenTimeout);
          if (amount) handleCalcFee(amount, value, body.sessionKey);
          // Send message to SDK to confirm that the token has been received and the key has been set
          util.sendMessage(
            {
              type: `pt-static:connected`,
              element: field.types,
            },
            origin,
            messageChannel.channel,
          );
          break;
        case TRANSFER_CONFIRMATION_TYPE:
          setIdempotency(body);
          // Ensures we only send the data we need to the front end
          const response = {
            amount: body.sale_input?.amount,
            fee: body.sale_input?.fee,
            first_six:
              field.type === 'account-number' ? 'XXXXXX' : value.slice(0, 6),
            last_four: value.slice(-4),
            brand: cardBrand
              ? brandMap[cardBrand]
              : field.type === 'account-number'
                ? 'ACH'
                : null,
            idempotency: body.sale_input?.transaction_id,
            fee_mode: body.sale_input?.fee_mode?.toLowerCase(),
          };
          util.sendMessage(
            {
              type: `pt-static:confirm`,
              body: response,
              field: field.type,
            },
            origin,
            messageChannel.channel,
          );
          break;
        case TRANSFER_COMPLETE_TYPE:
        case TOKENIZE_COMPLETE_TYPE:
          const completeType =
            TRANSFER_COMPLETE_TYPE === data.type ? 'transfer' : 'tokenize';
          util.sendMessage(
            {
              type: `pt-static:complete`,
              paymentType: completeType,
              body,
              field: field.type,
            },
            origin,
            messageChannel.channel,
          );
          break;
        case BARCODE_COMPLETE_TYPE:
          body.mapUrl = 'https://pay.vanilladirect.com/pages/locations';
          util.sendMessage(
            {
              type: `pt-static:cash-complete`,
              body,
              field: field.type,
            },
            origin,
            messageChannel.channel,
          );
          break;
        case CANCEL_TYPE:
          console.log('Transaction Canceled');
          break;
        case CALCULATE_FEE_TYPE:
          setCalculatedFee(body);
          util.sendMessage(
            {
              type: `pt-static:calculated_fee`,
              body: {
                fee: body.fee,
                payment_type: body.payment_type,
              },
              field: field.type,
            },
            origin,
          );
          break;
        default:
          if (messageChannel.channel) {
            util.sendMessage(
              {
                type: `pt-static:error`,
                error: 'SOCKET_ERROR: There was an error with the socket.',
                field: field.type,
              },
              origin,
              messageChannel.channel,
            );
          } else {
            util.sendMessage(
              {
                type: `pt-static:error`,
                error: 'SOCKET_ERROR: There was an error with the socket.',
                field: field.type,
              },
              origin,
            );
          }
          break;
      }

      if (data?.type !== HOST_TOKEN_TYPE && data?.type !== CALCULATE_FEE_TYPE) {
        resetFieldsReceived(fieldsReceived);
      }
    },
    [
      resetFieldsReceived,
      fieldsReceived,
      origin,
      field,
      messageChannel,
      keyPair.secretKey,
    ],
  );

  const debouncedSendCalculateFee = useDebounce(sendCalculateFee, 1000);
  const handleCalcFee = useCallback(
    (amountInput, valueInput, sessionKeyInput) => {
      // If the amount is different from the current amount, set the amount
      if (amountInput !== amount) setAmount(amountInput);

      // If the field is an account number and the amount is defined, send the calculate fee request
      if (amountInput && field.type === 'account-number') {
        sendCalculateFee(sessionKeyInput, amountInput, true, null);
      }
      // If the field is a card number and the amount is defined, run logic to determine if we have the bin and if we need to send the calculate fee request
      if (amountInput && field.type === 'card-number') {
        // Format the bank_id to only include the first 6 digits with no spaces
        const bank_id = valueInput.replace(/\s/g, '').slice(0, 6);
        // If the bank_id is less than 6, we don't have the bin and we need to clear the fee
        if (
          bank_id.length < 6 &&
          Object.keys(calculatedFee).length > 0 &&
          !calculatedFee.cleared
        ) {
          // Set cleared to true to clear the fee. This key will not exist if we receive a fee from the socket that is not cleared
          calculatedFee.cleared = true;
          // Used to set fee back to null when we don't have the bin
          util.sendMessage(
            {
              type: `pt-static:calculated_fee`,
              body: {
                fee: null,
                payment_type: null,
              },
              field: field.type,
            },
            origin,
          );
          // If the bank_id is 6 digits, we have the bin and we need to send the calculate fee request or if the b
        } else if (bank_id.length === 6) {
          // Only calc if the bank_id and amount are undefined or different from the current calculatedFee values
          if (
            calculatedFee.bank_id !== bank_id ||
            calculatedFee.total !== amountInput
          ) {
            // Set the bank_id and amount to the calculatedFee object so that we do not send the same request multiple times
            debouncedSendCalculateFee(
              sessionKeyInput,
              amountInput,
              false,
              bank_id,
            );
          } else if (calculatedFee.cleared) {
            // Set cleared to false so we can clear the fee and send to the front end again
            calculatedFee.cleared = false;
            util.sendMessage(
              {
                type: `pt-static:calculated_fee`,
                body: {
                  fee: calculatedFee.fee,
                  payment_type: calculatedFee.payment_type,
                },
                field: field.type,
              },
              origin,
            );
          }
        }
      }
    },
    [
      amount,
      debouncedSendCalculateFee,
      field.type,
      sendCalculateFee,
      calculatedFee,
      origin,
    ],
  );

  // const READY_CONNECTING = 0
  // const READY_OPEN = 1
  // const READY_CLOSING = 2
  // const READY_CLOSED = 3
  //Initializes the web socket and then sends a ready message
  useEffect(() => {
    if (ptSocket && ptToken && origin && isConnected === false) {
      const empty = () => {};
      const READY_ACTIONS = {
        0: () => {
          ptSocket.onopen = () => {
            if (transactingTypes.includes(field.type)) {
              requestHostToken(ptToken, origin);
            }
          };
          ptSocket.onmessage = messageCallback;
          ptSocket.onerror = errorCallback;
          ptSocket.onclose = event => {
            if (transactingTypes.includes(field.type)) {
              network.sendSOS(`SESSION_EXPIRED: ${event.reason}`);
            }
            // Reset values to allow reconnecting
            setSessionKey(undefined);
            setHostToken(undefined);
            setPtToken(false);
            setIsConnected(false);

            util.sendMessage(
              {
                type: `pt-static:error`,
                error: 'SESSION_EXPIRED',
                field: field.type,
              },
              origin,
            );
          };
          setIsConnected(true);
        },
        1: empty,
        2: empty,
        3: empty,
      };
      READY_ACTIONS[ptSocket.readyState]();
    }
  }, [
    ptSocket,
    ptToken,
    requestHostToken,
    field,
    fieldsReceived,
    transactingTypes,
    messageCallback,
    origin,
    isConnected,
    errorCallback,
  ]);

  useEffect(() => {
    if (ptSocket && messageCallback) {
      ptSocket.onmessage = messageCallback;
    }
  }, [ptSocket, messageCallback]);

  const attestationHandler = useCallback(
    attestation => {
      if (transactingTypes.includes(field.type)) {
        sendAttestation(sessionKey, field, attestation);
      }
    },
    [field, sendAttestation, sessionKey, transactingTypes, origin],
  );

  const transactHandler = useCallback(
    event => {
      if (!transactingTypes.includes(field.type)) {
        util.sendMessage(
          {
            type: `pt-static:relay`,
            value,
            element: field.type,
          },
          origin,
        );
      } else if (transactingTypes.includes(field.type)) {
        const billingInfo = event.billingInfo;
        // Check if the billingInfo is an object
        if (billingInfo instanceof Object) {
          stateObject['card-name'] = billingInfo.name;
          const address = billingInfo.address;
          if (address instanceof Object) {
            stateObject['billing-line1'] = address.line1;
            stateObject['billing-line2'] = address.line2;
            stateObject['billing-city'] = address.city;
            stateObject['billing-state'] = address.region;
            stateObject['billing-zip'] = address.postal_code;
          }
        }
        messageChannel.channel = event.ports[0];
      }
    },
    [valid, value, field.type, transactingTypes, origin],
  );

  const confirmHandler = useCallback(
    event => {
      messageChannel.channel = event.ports[0];
      requestTransferPart2(idempotency, sessionKey);
    },
    [requestTransferPart2, idempotency, sessionKey],
  );

  //Fetch new host token on message
  const requestHostTokenHandler = useCallback(
    message => {
      let data = message.data;
      let token = data?.token || message.token;
      let fields = data?.fields || message.fields;
      messageChannel.channel = message.ports[0];
      // If the message is a connection token, we need to create a new socket
      if (message.type === CONNECTION_TOKEN) {
        setPtSocket(network.createSocket(token));
      } else if (message.type === RESET_HOST) {
        // If the message is a reset host, we need to check if the socket is open and send a new host token or create a new socket
        if (ptSocket?.readyState === ptSocket?.OPEN) {
          requestHostToken(token, origin);
        } else {
          setPtSocket(network.createSocket(token));
        }
      }

      setPtToken(token);
      if (fields) {
        let fieldsObject = {};
        fields.forEach(fieldName => {
          if (fieldName === 'credit-card') {
            fieldsObject['card-cvv'] = false;
            fieldsObject['card-exp'] = false;
          } else if (field.type !== fieldName) {
            fieldsObject[fieldName] = false;
          }
        });
        setFieldsReceived(fieldsObject);
      }
    },
    [
      field.type,
      setFieldsReceived,
      setPtToken,
      setPtSocket,
      isConnected,
      requestHostToken,
      origin,
    ],
  );

  //Send cancel message to update the payment intent
  const cancelHandler = useCallback(() => {
    let paymentIntent = idempotency['payment_intent_id'];
    requestCancel(paymentIntent, sessionKey);
    setIdempotency(false);
  }, [requestCancel, idempotency, sessionKey, setIdempotency]);

  //Sets the details used for the transaction message to the socket
  const paymentDetailHandler = useCallback(
    event => {
      messageChannel.channel = event.ports[0];
      if (field.type === 'cash-name') {
        handleCashBarcode(event.data);
      } else {
        handleTransact(event.data);
      }
    },
    [handleTransact, handleCashBarcode, field.type],
  );

  // Sets the details used for tokenizing a payment method to the socket
  const tokenizeDetailHandler = useCallback(
    event => {
      messageChannel.channel = event.ports[0];
      handleTokenize(event.data);
    },
    [handleTokenize],
  );

  //Handles relay messages from the sibling frames for either autofill or single values
  const relayHandler = useCallback(
    message => {
      // Post messages to socket if relayed from sibling frame using socketAction
      if (message.siblingSocketEvent) {
        let { action, encoded } = message.value;
        socketAction(action, sessionKey, encoded);
      } else {
        message.element.endsWith('autofill')
          ? propagateAutofill(message)
          : handleStateRelay(message);
      }
    },
    [handleStateRelay, sessionKey, socketAction, propagateAutofill],
  );

  const buildCardAutofill = () => {
    let cardName = document.getElementById(CARD_NAME_ID);
    let cardCvv = document.getElementById(CARD_CVV_ID);
    let cardExp = document.getElementById(CARD_EXP_ID);
    return {
      'card-name': cardName ? cardName.value : '',
      'card-cvv': cardCvv ? cardCvv.value : '',
      'card-exp': cardExp ? cardExp.value : '',
    };
  };

  const buildAddressAutofill = () => {
    let line2 = document.getElementById(BILLING_LINE2_ID);
    let city = document.getElementById(BILLING_CITY_ID);
    let state = document.getElementById(BILLING_STATE_ID);
    let zip = document.getElementById(BILLING_ZIP_ID);
    return {
      'billing-line2': line2 ? line2.value : '',
      'billing-city': city ? city.value : '',
      'billing-state': state ? state.value : '',
      'billing-zip': zip ? zip.value : '',
    };
  };

  const sendAutofillMessage = useCallback(() => {
    let element =
      field.type === 'card-number' ? 'card-autofill' : 'address-autofill';
    let value =
      field.type === 'card-number'
        ? buildCardAutofill()
        : buildAddressAutofill();
    util.sendMessage(
      {
        type: `pt-static:relay`,
        value,
        element,
      },
      origin,
    );
    sendAutofill(sessionKey, field.type, { timing: getTiming() });
  }, [field.type, origin, sessionKey, getTiming, sendAutofill]);

  //Checks input events to validate autofill for Firefox, Edge, IE11, and iOS
  const inputCheckAutoComplete = useCallback(
    event => {
      if (
        ('insertReplacementText' === event.inputType || !('data' in event)) &&
        event.target.id === `${field.type}-hosted-field` &&
        ['card-number', 'billing-line1'].includes(field.type)
      ) {
        setTimeout(() => {
          sendAutofillMessage();
        }, 50);
      }
    },
    [field.type, sendAutofillMessage],
  );

  //Checks animationstart events to validate autofill for -webkit based browsers (Chrome and Safari)
  const animationCheckAutoComplete = useCallback(
    event => {
      if (
        'onautofillstart' === event.animationName &&
        event.target.id === `${field.type}-hosted-field` &&
        ['card-number', 'billing-line1'].includes(field.type)
      ) {
        setTimeout(() => {
          sendAutofillMessage();
        }, 50);
      }
    },
    [field.type, sendAutofillMessage],
  );

  const updateAmountHandler = useCallback(
    event => {
      handleCalcFee(event.amount, value, sessionKey);
    },
    [value, sessionKey, handleCalcFee],
  );

  //callbacks for event listeners so they can be removed/updated when state changes
  useWindowListener(confirmTypeMessage, confirmHandler);
  useWindowListener(cancelTypeMessage, cancelHandler);
  useWindowListener(paymentDetailTypeMessage, paymentDetailHandler);
  useWindowListener(requestHostTokenTypeMessage, requestHostTokenHandler);
  useWindowListener(tokenizeDetailTypeMessage, tokenizeDetailHandler);
  useWindowListener(attestationTypeMessage, attestationHandler);
  useWindowListener(transactTypeMessage, transactHandler);
  useWindowListener(relayTypeMessage, relayHandler);
  useWindowListener(updateAmountTypeMessage, updateAmountHandler);

  //adds and removes event listeners
  useEffect(() => {
    window.addEventListener('oncontextmenu', onMouse(MOUSE_CONTEXT));
    window.addEventListener('onmousedown', onMouse(MOUSE_DOWN));
    window.addEventListener('onmouseup', onMouse(MOUSE_UP));
    window.addEventListener('onkeyup', onKeyUp);
    window.addEventListener('animationstart', animationCheckAutoComplete);
    window.addEventListener('input', inputCheckAutoComplete);
    return () => {
      window.removeEventListener('oncontextmenu', onMouse(MOUSE_CONTEXT));
      window.removeEventListener('onmousedown', onMouse(MOUSE_DOWN));
      window.removeEventListener('onmouseup', onMouse(MOUSE_UP));
      window.removeEventListener('onkeyup', onKeyUp);
      window.removeEventListener('animationstart', animationCheckAutoComplete);
      window.removeEventListener('input', inputCheckAutoComplete);
    };
  }, [onKeyUp, onMouse, animationCheckAutoComplete, inputCheckAutoComplete]);

  //update state elements for the JS SDK
  useEffect(() => {
    if (origin) {
      setValid(func.validCheck(field.type, value));
      util.sendMessage(
        {
          type: `pt-static:state`,
          state: {
            isFocused: inFocus,
            isDirty: value.length > 0,
            errorMessages: func.validCheck(field.type, value, isConnected),
          },
          element: field.type,
        },
        origin,
      );
    }
  }, [value, inFocus, field.type, origin, isConnected]);

  return (
    <div
      className={`${field.type}-field pt-hosted-field`}
      style={{ height: '100%', width: '100%' }}>
      {field.type !== 'account-type' && (
        <input
          aria-label={field.aria}
          autoComplete={field.autoComplete}
          id={`${field.type}-hosted-field`}
          onBlur={e => {
            setBlurred({ timing: getTiming() });
            setInFocus(false);
          }}
          onChange={e => {
            let result = e.target.value;
            if (field.formatter) {
              result = field.formatter(e.target.value);
            }
            if (field.type === 'card-number') {
              setCardBrand(func.cardBrand(result));
              handleCalcFee(amount, result, sessionKey);
            }
            setValue(result);
          }}
          onContextMenu={onMouse(MOUSE_CONTEXT)}
          onFocus={e => {
            setFocused({ timing: getTiming() });
            setInFocus(true);
          }}
          onKeyUp={onKeyUp}
          onMouseDown={onMouse(MOUSE_DOWN)}
          onMouseUp={onMouse(MOUSE_UP)}
          pattern={field.numeric ? '[0-9]*' : ''}
          placeholder={hidePlaceholder ? '' : placeholder}
          style={
            style.success && value.length > 0 && valid.length === 0
              ? style.success
              : style.error && value.length > 0 && valid.length > 0
                ? style.error
                : style.default
          }
          type={field.numeric ? 'tel' : ''}
          value={value}
        />
      )}
      {field.type === 'account-type' && (
        <Radio
          placeholders={placeholders}
          setValue={setValue}
          styles={style.radio}
          value={value}
        />
      )}
      {(field.type === 'card-number' || field.type === 'billing-line1') && (
        <div className="hidden-fields">{setHiddenFields().map(h => h)}</div>
      )}
      {field.type === 'card-number' && <CardBrand cardBrand={cardBrand} />}
      <style jsx="true">
        {`
          .pt-hosted-field .hidden-fields input {
            max-height: 2px;
            max-width: 2px;
            border-color: transparent;
            color: transparent;
            border: 0;
            padding: 0;
            position: relative;
          }
          .pt-hosted-field .hidden-fields .hidden:-webkit-autofill,
          .pt-hosted-field .hidden-fields .hidden:-webkit-autofill:hover,
          .pt-hosted-field .hidden-fields .hidden:-webkit-autofill:focus {
            -webkit-text-fill-color: #fff !important;
            background: transparent;
          }
          .pt-hosted-field .hidden-fields {
            max-height: 2px;
            max-width: 2px;
            border-color: transparent;
            color: transparent;
            border: 0;
            padding: 0;
          }
        `}
      </style>
    </div>
  );
}
