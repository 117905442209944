import white from '../Images/pay_theory_primary_logo_white.svg';
import black from '../Images/pay_theory_primary_logo_black.svg';

export const calcStyle = style => {
  //default styles
  let result = {
    'background-color': '#9139D2',
    'alt-text': '',
    'border-radius': '4px',
    height: '48px',
    logo: white,
    'font-color': '#FFFFFF',
  };
  if (style) {
    const { color, callToAction, pill, height } = style;
    if (color) {
      switch (color.toLowerCase()) {
        case 'black':
          result['background-color'] = '#000000';
          break;
        case 'white':
          result['background-color'] = '#FFFFFF';
          result['logo'] = black;
          result['font-color'] = '#000000';
          result['border'] = '0.5px solid #C9C4CA';
          break;
        case 'grey':
          result['background-color'] = '#F7F7F4';
          result['logo'] = black;
          result['font-color'] = '#000000';
          break;
        default:
          break;
      }
    }
    if (callToAction) {
      switch (callToAction.toLowerCase()) {
        case 'pay':
          result['alt-text'] = 'Pay with';
          break;
        case 'checkout':
          result['alt-text'] = 'Checkout with';
          break;
        case 'donate':
          result['alt-text'] = 'Donate with';
          break;
        case 'book':
          result['alt-text'] = 'Book with';
          break;
        default:
          break;
      }
    }
    if (pill) {
      result['border-radius'] = '100px';
    }
    if (height) {
      result['height'] = height;
    }
  }
  return result;
};
