export const createSocket = token => {
  return new window.WebSocket(
    `wss://${process.env.REACT_APP_PARTNER}${process.env.REACT_APP_MODE}.secure.socket.${process.env.REACT_APP_STAGE}.com/${process.env.REACT_APP_PARTNER}/?pt_token=${token}`,
  );
};

export const sendSOS = fire_event => {
  const Source = document.location.ancestorOrigins[0];
  const Timestamp = Date.now();
  const data = { Source, Timestamp, fire_event };
  const url = `https://${process.env.REACT_APP_PARTNER}${process.env.REACT_APP_MODE}.${process.env.REACT_APP_STAGE}.com/firewatch-service/firewatch`;
  return navigator.sendBeacon(url, JSON.stringify(data));
};
