/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';

const VISA = 'visa';
const AMEX = 'american-express';
const MASTERCARD = 'mastercard';
const DISCOVER = 'discover';

const cardBrands = [VISA, MASTERCARD, AMEX, DISCOVER];
const brandNum = cardBrands.length;
var index = 0;

const CardBrand = ({ cardBrand }) => {
  const [cycleBrand, setCycleBrand] = useState(cardBrands[index]);

  useEffect(() => {
    let interval = setInterval(() => {
      index = (index + 1) % brandNum;
      setCycleBrand(cardBrands[index]);
    }, 2500);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="cardBrand">
      <span
        className={`pay-theory-card-badge pay-theory-card-visa ${
          cycleBrand === VISA && !cardBrand ? 'show' : 'hide'
        } ${cardBrand === VISA ? 'selected' : ''}`}
      />
      <span
        className={`pay-theory-card-badge pay-theory-card-mastercard ${
          cycleBrand === MASTERCARD && !cardBrand ? 'show' : 'hide'
        } ${cardBrand === MASTERCARD ? 'selected' : ''}`}
      />
      <span
        className={`pay-theory-card-badge pay-theory-card-american-express ${
          cycleBrand === AMEX && !cardBrand ? 'show' : 'hide'
        } ${cardBrand === AMEX ? 'selected' : ''}`}
      />
      <span
        className={`pay-theory-card-badge pay-theory-card-discover ${
          cycleBrand === DISCOVER && !cardBrand ? 'show' : 'hide'
        } ${cardBrand === DISCOVER ? 'selected' : ''}`}
      />
      <style global="true" jsx="true">
        {`
          .cardBrand {
            background-repeat: no-repeat;
            background-size: 100%;
            background-position: 50%;
            min-height: 24px;
            min-width: 38px;
            align-self: center;
            margin-right: 5px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
          }

          .cardBrand .show {
            opacity: 1;
            transition: opacity 0.4s;
          }

          .cardBrand .hide {
            opacity: 0;
            transition: opacity 0.4s;
          }

          .cardBrand .selected {
            opacity: 1;
          }

          .pay-theory-card-badge {
            background-repeat: no-repeat;
            background-size: 100%;
            background-position: 50%;
            min-height: 24px;
            min-width: 38px;
            align-self: center;
            margin-right: 5px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
          }

          .pay-theory-card-visa {
            background-image: url(https://books-ui-assets.s3.amazonaws.com/visa-color.svg);
          }

          .pay-theory-card-mastercard {
            background-image: url(https://books-ui-assets.s3.amazonaws.com/mastercard-color.svg);
          }

          .pay-theory-card-american-express {
            background-image: url(https://books-ui-assets.s3.amazonaws.com/amex-color.svg);
          }

          .pay-theory-card-discover {
            background-image: url(https://books-ui-assets.s3.amazonaws.com/discover-color.svg);
          }

          .pay-theory-card-default {
            background-image: url(https://books-ui-assets.s3.amazonaws.com/card-default-color.svg);
          }
        `}
      </style>
    </div>
  );
};

export default CardBrand;
